<template>
  <v-filter-modal
    class="owners-filters-mobile"
    :active-menu-item.sync="activeMenuItem"
    :menu="$options.NAME_MENU_ITEM"
    :count="filtersCount"
    @apply="applyFilters"
    @reset="resetFilters"
    @close="closeFilters"
  >
    <div v-if="!activeMenuItem" class="owners-filters-mobile__sorts">
      <div class="owners-filters-mobile__tabs-wrapper-title">СОРТИРОВАТЬ ПО</div>
      <div class="owners-filters-mobile__radio-wrapper" body-scroll-lock-ignore>
        <v-radio
          v-for="option in $options.SORT_OPTIONS"
          :key="option.label"
          v-model="filtersParams.orderBy"
          class="owners-filters-mobile__radio"
          :value="option.value"
          >{{ option.label }}</v-radio
        >
      </div>
    </div>

    <div class="owners-filters-mobile__menu">
      <v-menu v-if="!activeMenuItem" v-model="activeMenuItem">
        <v-menu-item name="category">
          Категория
          <span class="owners-filters-mobile__menu-item-preview"> {{ preview.category }} </span>
        </v-menu-item>
        <v-menu-item name="price">
          Цена
          <span class="owners-filters-mobile__menu-item-preview"> {{ pricePreview }} </span>
        </v-menu-item>
        <v-menu-item v-if="isRoleAdmin" name="agent">
          Агент
          <span class="owners-filters-mobile__menu-item-preview">
            {{ preview.agent }}
          </span>
        </v-menu-item>
        <v-menu-item name="date">
          Дата добавления
          <span class="owners-filters-mobile__menu-item-preview"> {{ datePreview }} </span>
        </v-menu-item>
        <v-menu-item name="lastReminder">
          Дата последней задачи
          <span class="owners-filters-mobile__menu-item-preview"> {{ lastReminderPreview }} </span>
        </v-menu-item>
        <v-menu-item name="documents">
          Статус
          <span class="owners-filters-mobile__menu-item-preview"> {{ preview.documents }} </span>
        </v-menu-item>
        <v-menu-item name="hasAgreement">
          Соглашение
          <span class="owners-filters-mobile__menu-item-preview"> {{ preview.hasAgreement }} </span>
        </v-menu-item>
        <v-menu-item name="hasEgrp">
          Выписка ЕГРН
          <span class="owners-filters-mobile__menu-item-preview"> {{ preview.hasEgrp }} </span>
        </v-menu-item>
        <v-menu-item name="hasTitleDocument">
          Правоустанавливающий документ
          <span class="owners-filters-mobile__menu-item-preview"> {{ preview.hasTitleDocument }} </span>
        </v-menu-item>
      </v-menu>
    </div>

    <div v-if="!agentOptionsLoading" class="owners-filters-mobile__sections-wrapper">
      <div v-show="activeMenuItem === 'category'" class="owners-filters-mobile__select">
        <filters-select
          v-model="selectedCategory"
          :options="categoryOptions"
          class="owners-filters-mobile__list-item"
        />
      </div>
      <div v-show="activeMenuItem === 'agent'" class="owners-filters-mobile__select">
        <filters-select v-model="selectedAgent" :options="agentOptions" class="owners-filters-mobile__list-item" />
      </div>
      <div v-show="activeMenuItem === 'documents'" class="owners-filters-mobile__select">
        <filters-select
          v-model="selectedDocumentStatus"
          :options="$options.DOCUMENTS_STATUS_OPTIONS"
          class="owners-filters-mobile__list-item"
        />
      </div>
      <div v-show="activeMenuItem === 'hasAgreement'" class="owners-filters-mobile__select">
        <filters-select
          v-model="selectedHasAgreement"
          :options="$options.DOCUMENTS_CHOICE_OPTIONS"
          class="owners-filters-mobile__list-item"
        />
      </div>
      <div v-show="activeMenuItem === 'hasEgrp'" class="owners-filters-mobile__select">
        <filters-select
          v-model="selectedHasEgrp"
          :options="$options.DOCUMENTS_CHOICE_OPTIONS"
          class="owners-filters-mobile__list-item"
        />
      </div>
      <div v-show="activeMenuItem === 'hasTitleDocument'" class="owners-filters-mobile__select">
        <filters-select
          v-model="selectedHasTitleDocument"
          :options="$options.DOCUMENTS_CHOICE_OPTIONS"
          class="owners-filters-mobile__list-item"
        />
      </div>

      <v-filter-price
        v-show="activeMenuItem === 'price'"
        :price-gte.sync="filtersParams.priceGte"
        :price-lte.sync="filtersParams.priceLte"
      />
      <v-date-publication
        v-show="activeMenuItem === 'date'"
        :date-from.sync="filtersParams.dateFrom"
        :date-to.sync="filtersParams.dateTo"
      />
      <v-date-publication
        v-show="activeMenuItem === 'lastReminder'"
        :date-from.sync="filtersParams.lastReminderFrom"
        :date-to.sync="filtersParams.lastReminderTo"
      />
    </div>
  </v-filter-modal>
</template>

<script>
import VFilterModal from '@/components/common/VFilterModal.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VRadio from '@/components/common/VRadio.vue'
import FiltersSelect from '@/components/Agent/AgentsSelect.vue'
import { advertsService, agentsService } from '@/services/http'
import { SORT_OPTIONS_MAP } from '@/constants/ownersOptions'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatDatePreview, formatMoney } from '@/utils/formatters'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VDatePublication from '@/components/common/VDatePublication.vue'
import VFilterPrice from '@/components/common/VFilterPrice.vue'

function generateFilters() {
  return {
    categoryId: undefined,
    agentId: undefined,
    orderBy: '-created_at',
    dateFrom: undefined,
    dateTo: undefined,
    lastReminderFrom: undefined,
    lastReminderTo: undefined,
    priceLte: undefined,
    priceGte: undefined,
    documentsStatus: undefined,
    hasEgrp: undefined,
    hasAgreement: undefined,
    hasTitleDocument: undefined
  }
}

function generatePreview() {
  return {
    agent: null,
    category: null,
    status: null,
    hasEgrp: null,
    hasAgreement: null,
    hasTitleDocument: null
  }
}

const NAME_MENU_ITEM_MAP = {
  agent: 'Агент',
  price: 'Цена',
  category: 'Категории',
  date: 'Дата добавления',
  lastReminder: 'Дата последней задачи',
  status: 'Статус',
  hasEgrp: 'Выписка ЕГРН',
  hasAgreement: 'Соглашение',
  hasTitleDocument: 'Правоустанавливающий документ'
}

const DOCUMENTS_STATUS_OPTIONS = [
  { name: 'Документы отсутствуют', id: 0 },
  { name: 'Документы загружены', id: 1 }
]

const DOCUMENTS_CHOICE_OPTIONS = [
  { name: 'Нет', id: 0 },
  { name: 'Да', id: 1 }
]

export default {
  SORT_OPTIONS: SORT_OPTIONS_MAP,
  NAME_MENU_ITEM: NAME_MENU_ITEM_MAP,
  DOCUMENTS_STATUS_OPTIONS,
  DOCUMENTS_CHOICE_OPTIONS,
  name: 'OwnersFiltersMobile',
  components: {
    VFilterPrice,
    VDatePublication,
    VFilterModal,
    VRadio,
    VMenu,
    VMenuItem,
    FiltersSelect
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    defaultFilters: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      agentOptions: [],
      categoryOptions: [],
      activeMenuItem: undefined,
      agentOptionsLoading: false,
      categoryOptionsLoading: false,
      filtersParams: generateFilters(),
      preview: generatePreview()
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    selectedCategory: {
      get() {
        return { id: this.filtersParams.categoryId }
      },
      set(category) {
        this.filtersParams.categoryId = category?.id
        this.preview.category = category?.name
      }
    },
    selectedAgent: {
      get() {
        const a = this.agentOptions.find(agent => agent.profileId === this.filtersParams.agentId)?.id
        return { id: a }
      },
      set(agent) {
        this.filtersParams.agentId = agent?.profileId
        this.preview.agent = agent?.name
      }
    },
    selectedDocumentStatus: {
      get() {
        return { id: this.filtersParams.documentsStatus }
      },
      set(status) {
        this.filtersParams.documentsStatus = status?.id
        this.preview.documents = status?.name
      }
    },
    selectedHasAgreement: {
      get() {
        return { id: this.filtersParams.hasAgreement }
      },
      set(status) {
        this.filtersParams.hasAgreement = status?.id
        this.preview.hasAgreement = status?.name
      }
    },
    selectedHasEgrp: {
      get() {
        return { id: this.filtersParams.hasEgrp }
      },
      set(status) {
        this.filtersParams.hasEgrp = status?.id
        this.preview.hasEgrp = status?.name
      }
    },
    selectedHasTitleDocument: {
      get() {
        return { id: this.filtersParams.hasTitleDocument }
      },
      set(status) {
        this.filtersParams.hasTitleDocument = status?.id
        this.preview.hasTitleDocument = status?.name
      }
    },
    pricePreview() {
      if (this.filtersParams.priceGte || this.filtersParams.priceLte) {
        return `${formatMoney(this.filtersParams.priceGte) || '0'} - ${formatMoney(this.filtersParams.priceLte) ||
          '...'} ₽`
      }
      return null
    },
    datePreview() {
      return formatDatePreview(this.filtersParams.dateFrom, this.filtersParams.dateTo)
    },
    lastReminderPreview() {
      return formatDatePreview(this.filtersParams.lastReminderFrom, this.filtersParams.lastReminderTo)
    },
    filtersCount() {
      const text = pluralizeNumeral(this.count, 'объект', 'объекта', 'объектов')
      return `${this.count} ${text}`
    }
  },
  watch: {
    defaultFilters: {
      immediate: true,
      handler({ orderBy, status, page, isDocumentsUploaded, hasAgreement, hasEgrp, hasTitleDocument, ...newFilters }) {
        this.filtersParams = { ...this.filtersParams, ...newFilters }
        this.filtersParams.documentsStatus =
          typeof isDocumentsUploaded === 'undefined' ? undefined : Number(isDocumentsUploaded === 'true')
        this.filtersParams.hasAgreement =
          typeof hasAgreement === 'undefined' ? undefined : Number(hasAgreement === 'true')
        this.filtersParams.hasEgrp = typeof hasEgrp === 'undefined' ? undefined : Number(hasEgrp === 'true')
        this.filtersParams.hasTitleDocument =
          typeof hasTitleDocument === 'undefined' ? undefined : Number(hasTitleDocument === 'true')
      }
    },
    agentOptions() {
      this.preview.agent = this.agentOptions.find(agent => agent.profileId === this.filtersParams.agentId)?.name
    },
    categoryOptions() {
      this.preview.category = this.categoryOptions.find(category => category.id === this.filtersParams.categoryId)?.name
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectCategory()
    this.selectDocumentsStatus()
    this.selectHasAgreement()
    this.selectHasEgrp()
    this.selectHasTitleDocument()
  },
  methods: {
    selectDocumentsStatus() {
      this.preview.documents = this.$options.DOCUMENTS_STATUS_OPTIONS.find(
        status => status.id === this.filtersParams.documentsStatus
      )?.name
    },
    selectHasAgreement() {
      this.preview.hasAgreement = this.$options.DOCUMENTS_CHOICE_OPTIONS.find(
        choice => choice.id === this.filtersParams.hasAgreement
      )?.name
    },
    selectHasEgrp() {
      this.preview.hasEgrp = this.$options.DOCUMENTS_CHOICE_OPTIONS.find(
        choice => choice.id === this.filtersParams.hasEgrp
      )?.name
    },
    selectHasTitleDocument() {
      this.preview.hasTitleDocument = this.$options.DOCUMENTS_CHOICE_OPTIONS.find(
        choice => choice.id === this.filtersParams.hasTitleDocument
      )?.name
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectCategory() {
      this.categoryOptionsLoading = true
      return advertsService
        .selectCategory()
        .then(categories => {
          this.categoryOptions = categories
        })
        .finally(() => {
          this.categoryOptionsLoading = false
        })
    },
    changeOrder(orderBy) {
      this.filtersParams.orderBy = orderBy
    },
    closeFilters() {
      this.$emit('close')
    },
    resetFilters() {
      this.filtersParams = generateFilters()
      this.preview = generatePreview()
    },
    applyFilters() {
      const { documentsStatus, hasAgreement, hasEgrp, hasTitleDocument, ...filtersParams } = this.filtersParams
      const isDocumentsUploaded = documentsStatus === undefined ? undefined : Boolean(documentsStatus)
      const hasEgrpLocal = hasEgrp === undefined ? undefined : Boolean(hasEgrp)
      const hasAgreementLocal = hasAgreement === undefined ? undefined : Boolean(hasAgreement)
      const hasTitleDocumentLocal = hasTitleDocument === undefined ? undefined : Boolean(hasTitleDocument)
      this.$emit('apply-filters', {
        ...filtersParams,
        isDocumentsUploaded,
        hasAgreement: hasAgreementLocal,
        hasEgrp: hasEgrpLocal,
        hasTitleDocument: hasTitleDocumentLocal
      })
    }
  }
}
</script>
